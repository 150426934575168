import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import HeaderImage from '../Image/HeaderImage';
import HeaderMainImage from '../Image/HeaderMainImage';

const About = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <div className="about-inner">
        <Fade duration={500} delay={250} distance="30px">
          <img
            src="/logo.svg"
            alt="Belfast Overdose"
            style={{ width: 'calc(100% - 40px)' }}
            className="logo"
          />
          <p>
            2,5 Mio Plays bei Spotify – der Independent Charts Pop der DIY-Boygroup Belfast Overdose schlägt ein. Der fliegende Backstein ins süddeutsche Reiheneckhaus – kurz vorm Tatort. Wie jede echte Band kennen sich die vier Jungs aus dem Sandkasten. Heute zelebrieren sie voller Euphorie ihren Belfast Vibe und haben keine Scheu das Chaos im Backstage mit allen zu teilen. 
          </p>
        </Fade>
      </div>
    </section>
  );
};

export default About;
