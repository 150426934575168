import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import HeaderImage from '../Image/HeaderImage';
import HeaderMainImage from '../Image/HeaderMainImage';
import logo from './logo-typo.svg';

const Header = () => {
  const { header } = useContext(PortfolioContext);
  const { leftImage, rightImage, mainImage, logoImage } = header;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="header">
      <HeaderImage
        filename={leftImage}
        alt="Belfast Overdose Hintergrundbild"
        className="bg-image bg-image-left"
      />
      <HeaderImage
        filename={rightImage}
        alt="Belfast Overdose Hintergrundbild"
        className="bg-image"
        objectPosition="right"
        objectSize="200%"
      />
      <div className="main-image">
        <Fade duration={1000} delay={500} distance="30px">
          <img src={logo} alt="Belfast Overdose Logo" className="logo"/>
          <HeaderMainImage filename={mainImage} alt="Belfast Overdose Profilbild" />
          {/* <img src={mainImage} alt="" className="main-image" /> */}
        </Fade>
      </div>
    </section>
  );
};

export default Header;
