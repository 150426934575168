import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import {
  headerData,
  heroData,
  aboutData,
  projectsData,
  contactData,
  footerData,
} from '../mock/data';
import Header from './Header/Header';
import Video from './Video/Video';
import Logo from './Logo/Logo';

function App() {
  const [header, setHeader] = useState({});
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, hero, about, projects, contact, footer }}>
      <Header />
      <Video />
      {/* <Logo /> */}
      <About />
      {/* <Hero /> */}
      {/* <Projects /> */}
      {/* <Contact /> */}
      {/* <Footer /> */}
    </PortfolioProvider>
  );
}

export default App;
