import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import HeaderImage from '../Image/HeaderImage';
import HeaderMainImage from '../Image/HeaderMainImage';

const Video = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="video">
      <div className="video-inner">
        <Fade duration={1000} delay={250} distance="30px">
          <img src="/video-title.svg" alt="Musikvideo Meldoy von Vescu feat. Belfast Overdose" />
          
          <div className="responsive-video">
            <iframe
              title="Melody Music Video"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/e2eBpXuFLpA"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Video;
